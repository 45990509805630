<template>
    <div>
        <div class="columns is-centered" v-if="bigEnoughtProjects">
            <table style="text-align:left" class="table is-bordered  is-vcentered is-hoverable">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th><abbr title="Date of Release">DOR</abbr></th>
                        <th>Status</th>
                        <th>Language</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>StringToBool</th>
                        <td>A library able to translate Strings containing boolean operation in a True/False statement </td>
                        <td>May 3rd 2022</td>
                        <td>
                            <div class="tag is-success is-medium">Completed (to be refined)</div>
                        </td>
                        <td>Java</td>
                        <td><a href="https://github.com/Jak3d/StringToBoolean" target="_blank"><button class="button is-link"><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                    </tr>
                    <tr>
                        <th>WebPage</th>
                        <td>My own WebPage</td>
                        <td>TBD</td>
                        <td>
                            <div class="tag is-warning is-medium">In Progress</div>
                        </td>
                        <td>Vue | javascript | HTML</td>
                        <td> <a href="https://github.com/Jak3d/LeonardoMarro" target="_blank"><button class="button is-link"><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>

                    </tr>
                    <tr>
                        <th>Sync Ferrero</th>
                        <td>A visual application to manage an SSL server</td>
                        <td>June 18 2019</td>
                        <td>
                            <div class="tag is-info is-medium">Private</div>
                        </td>
                        <td>C#</td>
                        <td><a href="..." target="_blank"><button class="button is-danger" disabled><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                    </tr>
                    <tr disabled>
                        <th>TBD</th>
                        <td>Thesis project, to be release once project has started</td>
                        <td>TBD</td>
                        <td>
                            <div class="tag is-warning is-medium">In Progress</div>
                        </td>
                        <td>Python</td>
                        <td><a href="" target="_blank"><button class="button is-link" disabled><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="!bigEnoughtProjects" style="margin-left: 1%;margin-right: 2%;">
            <div class="columns">
                <div class="column is-full-mobile">
                    <div class="card">
                        <div class="card-content">
                            <p class="title">StringToBool</p>
                            <p class="subtitle">
                                A library able to translate Strings containing boolean operation in a True/False statement
                            </p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item" style="background-color: #48c78e;">
                                <span style="color: 212121;">
                                   May 23rd 2022
                                </span>
                            </p>
                            <p class="card-footer-item" style="background-color: #48c78e;">
                                <span style="width: 100%;height: 100%; color: 212121;">
                                    Completed
                                </span>
                            </p>
                        </footer>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    <td><a href="https://github.com/Jak3d/StringToBoolean" target="_blank"><button class="button is-link"><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                                </span>
                            </p>
                            
                        </footer>
                    </div>
                </div>
                <div class="column">
                    <div class="card">
                        <div class="card-content">
                            <p class="title">WebPage</p>
                            <p class="subtitle">My own WebPage</p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item" style="background-color: #FFD700;">
                                <span style="color: 212121;">
                                   Ver 1.0 24th August 2023
                                </span>
                            </p>
                            <p class="card-footer-item" style="background-color: #FFD700;">
                                <span style=" color: 212121;" >
                                    In Progress
                                </span>
                            </p>
                        </footer>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    <td><a href="https://github.com/Jak3d/LeonardoMarro" target="_blank"><button class="button is-link"><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                                </span>
                            </p>
                            
                        </footer>
                    </div>
                </div>
                <div class="column">
                    <div class="card">
                        <div class="card-content">
                            <p class="title">Sync Ferrero</p>
                            <p class="subtitle">A visual application to manage an SSL server</p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item" style="background-color: #6495ED;">
                                <span style="color: 212121;">
                                   June 18th 2019
                                </span>
                            </p>
                            <p class="card-footer-item" style="background-color: #6495ED;">
                                <span style=" color: 212121;" >
                                    Private
                                </span>
                            </p>
                        </footer>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    <td><a href="..." target="_blank" ><button class="button is-link" disabled><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>
                <div class="column">
                    <div class="card">
                        <div class="card-content">
                            <p class="title">TBD</p>
                            <p class="subtitle">Thesis project, to be release once project has started</p>
                        </div>
                        <footer class="card-footer">
                            <p class="card-footer-item" style="background-color: #6495ED;">
                                <span style="color: 212121;">
                                   TBD
                                </span>
                            </p>
                            <p class="card-footer-item" style="background-color: #6495ED;">
                                <span style=" color: 212121;" >
                                    Private
                                </span>
                            </p>
                        </footer>
                        <footer class="card-footer">
                            <p class="card-footer-item">
                                <span>
                                    <td><a href="..." target="_blank" ><button class="button is-link" disabled><font-awesome-icon icon="fa-brands fa-github" />&zwnj; View on GitHub</button></a></td>
                                </span>
                            </p>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProjectPage',

    data() {
        return {
            bigEnoughtProjects: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            loaded: false,
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.resizeOnLoad();
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        expand() {
            console.log('expanding');
            this.expanded = true;
        },
        unExpand() {
            this.expanded = false;
        },
        toggleLowRes() {
            this.low_res_mode = !this.low_res_mode;
            console.log(this.low_res_mode);
        },
        myEventHandler(e) {
            console.log('cought e');
            console.log(e);
            console.log(e.type);
            if (e.type == "resize") {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
                console.log('resize');
            }
        },
        resizeOnLoad() {
            console.log('loaded');
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            if (this.windowWidth < 1000) {
                this.bigEnoughtProjects = false;
                console.log('CorrectSize -- Updated CV');
            } else {
                this.bigEnoughtProjects = true;
                console.log('CorrectSize -- Updated CV');
            }
            console.log(this.bigEnoughtProjects);
        }
    },
    mounted() {
        window.onload = () => {

            console.log('loaded CV');
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            if (this.windowWidth < 1000) {
                this.bigEnoughtProjects = false;
            } else {
                this.bigEnoughtProjects = true;
            }
            console.log(this.bigEnoughtProjects);
        },
            window.onresize = () => {
                this.windowWidth = window.innerWidth;
                if (this.windowWidth < 1000) {
                    this.bigEnoughtProjects = false;
                } else {
                    this.bigEnoughtProjects = true;
                }
                console.log(this.loaded);
            }

    },


}
</script>
<style></style>