<template >
    <div>
        
        <div v-if="expanded===false">
            <p v-if="bigEnought2===true" @click="expand()">Click to expand</p>
            <img @click="expand()" src="../CV.jpg" alt="Curriculum Vitae Leonardo Marro" :style="'max-height:'+this.windowHeight+'px'">
            
        </div>
        <div v-if="expanded===true">
            <p v-if="bigEnought2===true" @click="unExpand()">Click to reduce</p>
            <img @click="unExpand()" src="../CV.jpg" alt="Curriculum Vitae Leonardo Marro" style="min-width: 100%; min-height: 100%; ">
        </div>
    </div>
    
</template>

<script>
export default {
    components: {
    },
    name: 'CV',
    data() {
        return {
            low_res_mode: false,
            bigEnought2: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            loaded: false,
            expanded: false,
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.resizeOnLoad();
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        expand(){
            console.log('expanding');
            this.expanded=true;
        },
        unExpand(){
            this.expanded=false;
        },
        toggleLowRes() {
            this.low_res_mode = !this.low_res_mode;
            console.log(this.low_res_mode);
        },
        myEventHandler(e) {
            console.log('cought e');
            console.log(e);
            console.log(e.type);
            if (e.type == "resize") {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
                console.log('resize');
            }
        },
        resizeOnLoad() {
            console.log('loaded');
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            if (this.windowWidth < 1000) {
                this.bigEnought2 = false;
                console.log('CorrectSize -- Updated CV');
            } else {
                this.bigEnought2 = true;
                console.log('CorrectSize -- Updated CV');
            }
            console.log(this.bigEnought2);
        }
    },
    mounted() {
        window.onload = () => {

            console.log('loaded CV');
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            if (this.windowWidth < 1000) {
                this.bigEnought2 = false;
            } else {
                this.bigEnought2 = true;
            }
            console.log(this.bigEnought2);
        },
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 1000) {
                this.bigEnought2 = false;
            } else {
                this.bigEnought2 = true;
            }
            console.log(this.loaded);
        }
        
    },
    

}
</script>
<style>
.div {
    background-color: #ffe2d1;
    ;
}
</style>